import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, FloatingLabel, Modal, Button } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import Payment from './Payment';
import './CustomOrder.css';
import axios from 'axios';

const CustomOrder = ({
    currencyConversionRates,
    mongoURI,
    currencySymbols,
    country,
    currency,
    couponIcon,
    emailIcon,
    whyIcon
}) => {
    const [grossAmount, setGrossAmount] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [convertedAmount, setConvertedAmount] = useState(0);
    const [showReferralInput, setShowReferralInput] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [referralLabel, setReferralLabel] = useState({ text: 'Referral Code (Optional)', color: 'black' });
    const [discount, setDiscount] = useState(0);
    const [netAmount, setNetAmount] = useState(0);
    const [email, setEmail] = useState('');
    const [showModal, setShowModal] = useState(false);
    const grossAmountRef = useRef(null);

    useEffect(() => {
        grossAmountRef.current?.focus();
        grossAmountRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, []);

    useEffect(() => {
        if (grossAmount) {
            handleAmountChange({ target: { value: grossAmount } });
        }
    }, [discount]);

    const handleAmountChange = (e) => {
        const enteredAmount = e.target.value;
        const amountAfterDiscount = Math.round(parseFloat(enteredAmount) * (1 - discount / 100));
        setGrossAmount(enteredAmount);
        setNetAmount(amountAfterDiscount);

        if ((amountAfterDiscount / currencyConversionRates[currency]) < 0.8) {
            setErrorMessage(`Minimum amount${discount ? ' after discount' : ''} should be ${currencySymbols[currency] || currency}${Math.ceil(0.8 * currencyConversionRates[currency])}`);
            return;
        } else {
            setErrorMessage('');
        }
        setConvertedAmount(amountAfterDiscount * 100);
    };
    useEffect(() => {
        handleReferralCodeChange();
    }, [referralCode]);

    const handleReferralCodeChange = async () => {
        if (!referralCode) {
            setDiscount(0);
            setReferralLabel({ text: 'Referral Code (Optional)', color: 'black' });
            return;
        }
        if (referralCode.length === 3) {
            setReferralLabel({ text: 'Validating referral code...', color: 'blue' });
            try {
                const response = await axios.post(`${mongoURI}validateReferralCode`, { referralCode });
                if (response.data.valid) {
                    setDiscount(response.data.discount);
                    setReferralLabel({ text: `${response.data.discount}% discount applied!`, color: 'green' });
                } else {
                    setDiscount(0);
                    setReferralLabel({ text: 'Referral Code Not Valid', color: 'red' });
                }
            } catch (error) {
                console.error('Error validating referral code:', error);
                setDiscount(0);
            }
        } else {
            setReferralLabel({ text: 'Referral Code Not Valid', color: 'red' });
            setDiscount(0);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (grossAmount && convertedAmount > 0) {
            setSubmitted(true);
            setErrorMessage('');
        } else {
            setErrorMessage('Please enter a valid amount.');
        }
    };

    const handlePaymentSuccess = async (paymentIntent) => {
        setShowModal(true);
        let tempOrder = {
            email,
            referralCode: referralCode || null,
            discount: discount || 0,
            paymentIntentId: paymentIntent.id,
        };
        let addCustomOrder = await axios.post(`${mongoURI}addCustomOrder`, tempOrder);
        if (addCustomOrder.data.error) {
            setErrorMessage(addCustomOrder.data.error);
            return;
        }
        try {
            await axios.post(`${mongoURI}sendConfirmationEmail`, {
                orderId: addCustomOrder.data,
                email,
            });
        } catch (error) {
            console.error('Error sending confirmation email:', error);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        window.location.href = '/';
    };

    const handleTrackOrder = () => {
        window.location.href = `/order-tracking?email=${encodeURIComponent(email)}`;
    };

    return (
        <div className="custom-order-section">
            <Container>
                <Row>
                    <Col md={8} className="mx-auto">
                        <h2 className="custom-order-title">Custom Order Payment</h2>
                        <p className="custom-order-subtitle">Experience the best services tailored to your needs.</p>
                        {!submitted ? (
                            <Form onSubmit={handleSubmit}>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="currency-symbol platform-icon-small me-2">{currencySymbols[currency]}</div>
                                    <Form.Control
                                        type="number"
                                        placeholder="Amount"
                                        value={grossAmount}
                                        onChange={handleAmountChange}
                                        ref={grossAmountRef}
                                        required
                                    />
                                </div>

                                <div className="d-flex align-items-center mb-3">
                                    <img src={emailIcon} alt="Email" className="platform-icon-small me-2" />
                                    <Form.Control
                                        type="email"
                                        placeholder="Email for Order Receipt"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    
                                </div>

                                {!showReferralInput && (
                                    <div className="mb-2 text-center">
                                        <a href="#" className="referral-link" onClick={() => setShowReferralInput(true)}>
                                            Have a referral code?
                                        </a>
                                    </div>
                                )}

                                {showReferralInput && (
                                    <div className="d-flex align-items-center mb-3">
                                        <img src={couponIcon} alt="Referral Code" className="platform-icon-small me-2" />
                                        <FloatingLabel controlId="referralCodeInput" label={referralLabel.text} className={`flex-grow-1 ${referralLabel.color}Text`}>
                                            <Form.Control
                                                type="text"
                                                placeholder={referralLabel.text}
                                                value={referralCode}
                                                onChange={(e) => setReferralCode(e.target.value)}
                                                onBlur={handleReferralCodeChange}
                                            />
                                        </FloatingLabel>
                                    </div>
                                )}

                                {errorMessage && <div className="error-message">{errorMessage}</div>}

                                <Button
                                    type="submit"
                                    className="submitButton"
                                    variant="primary"
                                    disabled={errorMessage || !grossAmount || grossAmount <= 0}
                                >
                                    Proceed to Pay {grossAmount && `${currencySymbols[currency]}${parseFloat(netAmount).toFixed(2)}`}
                                </Button>
                            </Form>
                        ) : (
                            <Payment
                                currencySymbols={currencySymbols}
                                amount={convertedAmount}
                                currency={currency}
                                country={country}
                                onPaymentSuccess={handlePaymentSuccess}
                                mongoURI={mongoURI}
                                email={email}
                            />
                        )}
                    </Col>
                </Row>
            </Container>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton className="modal-header-custom">
                    <Modal.Title className="modal-title-custom">Order Confirmed</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FaCheckCircle size={80} className="confirmation-icon" />
                    <h3 className="mt-3">Thank you! Your order has been placed.</h3>
                    <p className="mt-2">A receipt has been sent to your email: {email}</p>
                    <div className="order-details mt-4">
                        <p><strong>Order Amount:</strong> {`${currencySymbols[currency]}${parseFloat(grossAmount).toFixed(2)}`}</p>
                        {discount > 0 && <p><strong>Discount Applied:</strong> {discount}%</p>}
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer-custom">
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Place New Order
                    </Button>
                    <Button variant="primary" onClick={handleTrackOrder}>
                        Track Order
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CustomOrder;
