import React, { useState, useEffect, useRef } from 'react';
import { Button, Accordion, Form } from 'react-bootstrap';
import { FaWhatsapp, FaEnvelope, FaChevronDown } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Chatbot.css';
import axios from 'axios';
import {Link} from 'react-router-dom'

const Chatbot = ({ mongoURI, isChatOpen, setIsChatOpen }) => {
  const apiUrl = `${mongoURI}userMessages`;
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [threadID, setThreadID] = useState(localStorage.getItem('threadID') || null);
  const chatBoxRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages, isChatOpen]);

  const fetchMessages = async () => {
    try {
      const response = await axios.post(apiUrl, { threadID });
      setMessages((response.data.messages || []));
      scrollToBottom();
    } catch (error) {
      console.error('Failed to load chat history:', error);
    }
  };

  const sendMessage = async (message) => {
    setMessages((prevMessages) => [...prevMessages, { role: 'user', content: message }]);

    try {
      const response = await axios.post(apiUrl, { threadID, incomingMessage: message });
      setMessages((response.data.messages || []));

      if (!threadID) {
        setThreadID(response.data.threadID);
        localStorage.setItem('threadID', response.data.threadID);
      }
      scrollToBottom();
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValue.trim() === '') return;
    sendMessage(inputValue);
    setInputValue('');
  };

  useEffect(() => {
    if (threadID) {
      fetchMessages();
    }
  }, [threadID]);

  return (
    <div className={`chatbot-container ${isChatOpen ? 'open' : ''}`}>
      {isChatOpen && (
        <div className="chatbot">
          <div className="chatbot-header" onClick={() => setIsChatOpen(false)}>
            <h3>FAQ & Support</h3>
            <FaChevronDown style={{ float: 'right' }} />
          </div>

          <div className="chat-content">
            <div className="chatbot-messages" ref={chatBoxRef}>

              {/* FAQs Displayed as Chat Messages */}

              <div className="chatbot-message assistant">
                <p><strong>How long does it take to process my order?</strong><br />
                  The approximated order processing time is shown on the <Link to='/order-tracking' onClick={()=>setIsChatOpen(false)}>order tracking page</Link> and next to quantity input. Larger orders may take a bit longer.
                </p>
              </div>

              <div className="chatbot-message assistant">
                <p><strong>What if my followers or engagement drops?</strong><br />
                  We offer free refills if your followers or engagement drop. No questions asked!
                </p>
              </div>
              <div className="chatbot-message assistant">
                <p><strong>Do you offer custom packages?</strong><br />
                  Yes, we do! If you need a custom package tailored to your specific needs, contact us via <a href='mailto:mail@buzzboostr.com'>Email</a> or <a href='https://wa.me/64225401142'>WhatsApp</a>.
                </p>
              </div>
              
              <div className="chatbot-message assistant">
                <p>Can't find your answer in the FAQs above? Ask the assistant. Or contact us via WhatsApp or Email:</p>
                <div className="contact-buttons">
                  <Button variant="success" href="https://wa.me/64225401142" className="button" aria-label="WhatsApp">
                    <FaWhatsapp /> WhatsApp
                  </Button>
                  <Button variant="primary" href="mailto:mail@buzzboostr.com" className="button" aria-label="Email">
                    <FaEnvelope /> Email
                  </Button>
                </div>
              </div>

              {messages.length > 0 ? (
                messages.map((msg, idx) => (
                  <div key={idx} className={`chatbot-message ${msg.role}`}>
                    {msg.content}
                  </div>
                ))
              ) : (
                <div className="no-messages">Start the conversation!</div>
              )}

              {/* Chat Input */}
              <Form onSubmit={handleSubmit} className="chatbot-input-form">
                <Form.Control
                  as="textarea"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Type a message..."
                  className="chat-input"
                  rows={1}
                />
                <Button variant="primary" type="submit" className="send-button">
                  Send
                </Button>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
